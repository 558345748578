export const projectData = [
    {
        img: 'logos/Docker.img',
        translationKey: 'moder'
    },
    {
        img: 'logos/Figma.img',
        translationKey: 'kvh'
    },
    {
        img: 'logos/empty.img',
        translationKey: 'more'
    },
]